<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('procedure.new_procedure') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6">
                                <label for="name">
                                    {{ $t('name') }}<span class="text-danger">*</span>
                                </label>
                                <input v-model="filters.name" type="text" min="1" id="name" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="name">
                                {{$t('type')}}
                            </label>
                            <select name="" id="type" v-model="filters.type" type="text" class="custom-select" :class="validation && validation.abbreviation ? 'is-invalid' : ''">>
                                <option :value="null">{{$t('global.all')}}</option>
                                <option v-for="row in type_procedure_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                            </select>
                            <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.abbreviation[0] }}
                            </span>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="name">{{$t('status')}}</label>
                            <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option value="1">{{ $t('active') }}</option>
                                <option value="0">{{ $t('inactive') }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div class="d-flex justify-content-end mb-5">
                    <custom-export-data v-if="$can('procedures.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                </div>
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('procedures.change_status')"
                                size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                                v-model="props.row.is_active"
                                :name="'check-button'+props.row.id"
                                switch :key="props.row.id">
                        </b-form-checkbox>
                        <b-form-checkbox v-else
                                size="lg" :disabled="true"
                                v-model="props.row.is_active"
                                :name="'check-button'+props.row.id"
                                switch :key="props.row.id">
                        </b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-if="$can('procedures.update')"  v-b-tooltip.hover :title="$t('edit')" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-if="$can('procedures.delete')" v-b-tooltip.hover :title="$t('delete')" @click="deleteprocedure(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('procedure.procedure')">
          <div class="row">
            <div class="col-md-6 mb-5">
              <label for="name">
                {{ $t('name') }}<span class="text-danger">*</span>
              </label>
              <input v-model="procedure.name" type="text" min="1" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label for="name">
                {{$t('type')}}
              </label>
              <select name="" id="type" v-model="procedure.type" type="text" class="custom-select" :class="validation && validation.type ? 'is-invalid' : ''">
                <option :value="null" disabled>{{$t('type')}}</option>
                <option v-for="row in type_procedure_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.type[0] }}
                </span>
            </div>
            <div class="col-md-6 mb-5">
              <label>{{$t('status')}}:</label>
              <b-form-checkbox size="lg" v-model="procedure.is_active" name="check-button" switch></b-form-checkbox>
            </div>

            <div class="col-md-12 mb-5">
              <label for="name">
                {{ $t('notes') }}
              </label>
              <textarea v-model="procedure.notes" style="height: 63px;" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.notes[0] }}
                </span>
            </div>

          </div>
            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                procedure: {
                    name: null,
                    notes: null,
                    type: null,
                    is_active: false,
                },
                showAdvancedSearch: false,
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    type: '',
                    code_method: '',
                },
                columns: ['name', 'type_name', 'user', 'created_at', 'status', 'actions'],
                validation: null,
                mainRoute: 'settings/procedures',
                routeChangeStatus: 'settings/procedure/change-status',
                isEditing: false,
                id: null,
                type_procedure_list: [],
                checkedValue: "",
                dataList: [],
            }
        },
        watch: {
            "procedure.is_active": function (val) {
                console.log('df');
                if(!val){
                    console.log('df');
                    this.procedure.is_active = false;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('name')] = 'name';
                fields[this.$t('type')] = 'type_name';
                fields[this.$t('user')] = 'user';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('status')] = 'is_active';
                return fields;
            },
            fileName: function () {
                return this.$t('procedure.procedure');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('name'),
                        type_name: that.$t('type'),
                        user: that.$t('user'),
                        created_at: that.$t('created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['type'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.procedure_management")}]);
            this.getTypeProcedureList();
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                });
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.type = '';
                this.filters.name = '';
                this.filters.is_active = '';

                this.$refs.table.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.updateCodeSetting();
                } else {
                    this.saveCodeSetting();
                }
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(procedure) {
                ApiService.delete(`${this.mainRoute}/${procedure.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
            deleteprocedure(procedure) {
                this.$confirmAlert('', this.actionDelete, procedure);
            },

            showModal(data) {
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.getData(data.id);
                    this.$refs['modal'].show();
                }

            },
            hideModal() {
                this.reset();
                this.$refs['modal'].hide();
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            saveCodeSetting() {
                ApiService.post(this.mainRoute, this.procedure).then((response) => {
                    this.$successAlert(response.data.message);
                    this.aftersaveCodeSetting();
                }).catch((errors) => {
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            updateCodeSetting() {
                ApiService.put(`${this.mainRoute}/${this.id}`, {
                    ...this.procedure,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.aftersaveCodeSetting();
                })
                    .catch((errors) => {
                        this.validation = errors.response ? errors.response.data.errors : null;
                    });
            },

            getData(id) {
                ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
                    this.isEditing = true;

                    this.procedure.name = response.data.data.name;
                    this.procedure.type = response.data.data.type;
                    this.procedure.notes = response.data.data.notes;
                    this.procedure.is_active = response.data.data.is_active;
                });
            },

            reset() {
                this.procedure = {
                    type: '',
                    name: '',
                    is_active: '',
                    notes: '',
                };
                this.validation = [];
                this.isEditing = false;
            },


            getTypeProcedureList() {
                ApiService.get('base/dependency/type_procedure').then(response => {
                    this.type_procedure_list = response.data.data;
                });
            },

            aftersaveCodeSetting() {
                this.procedure = {
                    type: '',
                    name: '',
                    is_active: '',
                    notes: '',
                };
                this.hideModal();
                this.getFetch();
            }
        },
    };
</script>
